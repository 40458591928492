.nav-main-box {
    border-color: 'divider';
}

.tab-indicators {
    height: 4px;
    text-transform: 'uppercase';
}

.cancelTabsButton {
    font-size: 10px;
    min-width: 10px;

}

.navBarArrowTabs {
    display: inline-flex;
}

.prizeLangSelectClass {
max-width: 650px;
}

.langTabStyles {
    min-width: max-content;
}
