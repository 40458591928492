.pageWrap {
    width: 1400px;
    margin: 0 auto;
    padding-top:40px;
    display: inline-table;
    text-align: left;
}
.button {
    display:inline-block;
}
.subHeaderTitle {
    font-weight:bold;
    margin: 0px 10px;
}
.archiveCampaignButton {
    font-weight:bold;
    float: right
}

.subnav-promo-name {
    font-weight: bold;
    font-size: 12px;
    opacity: 1;
    color:#43425D
}
