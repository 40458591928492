.modal_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    box-shadow: 24px;
    padding: 8px 32px;
}
.body {
    text-align: center;
    margin-bottom: 16px;
}

.subheader {
    text-align: left;
    margin-bottom: 5px;
}

.button {
    float: right;
}

.tier-subheader {
    text-align: left;
    margin-bottom: 5px;
    padding-left: 10%;
}

.tier-input {
    text-align: center;
}

.tooltip-link {
    font-size: 12px;
    color: #f30000;
    text-decoration: none;
}

.voucher-upload-subheader {
    text-align: left;
    margin-bottom: 5px;
}
