.dialogBoxStyles {
 max-width: 100%;
 max-height: 100%;
}
.dialogPaperStyles {
    width: 300px;
    height: 150px;
    background-color: 'transparent'
}
.dialogueContentTextStyles {
    font-size: 16px;
}
.dialogueButtonStyles {
    font-weight: bold;
}
.alertStyles {
    width: 260px
}
