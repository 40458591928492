.UploadPrizesTab {
    margin-bottom: 10px;
}

.uploadModalHeader {
    align-items: center;
    display: flex
}

.uploadModalCloseIcon {
    margin-left: auto;
    margin-right: 0;
}