.bigLabelStyles {
    font-size: 24px;
    font-weight: bold;
    position: relative;
    min-width: 120px;
    max-width: 250px;
    height: 50px;
    left: 1px;
    top: 65px;
    text-align: left;
}
.createNewItemButtonStyles {
    position: relative;
    left: 100px;
    font-weight: bold;
    bottom: 10px;
}
.searchBarStyles {
    width: 460px;
    height: 10px;
    position: relative;
    left: 125px;
    top: -10px;
    margin-bottom: 50px;
}
.breadCrumbsStyles {
    position: relative;
    top: 65px;
}
