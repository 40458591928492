.totalItemsLabelStyles {
    text-align:left;
    margin-top:10px;
    font-size: 12px;
    font-weight: bold;
    }
.paginationBarStyles {
    margin-top: 20px
}
.gridStyles  {
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
}
.containerStyles {
    padding-left: 20px;
    padding-right: 0px;
    background:white;
    margin-top:20px;
    min-height: 636px;
}

.boxGridItems {
    min-height: 600px;
}
