.navbar {
    .rowC{display:flex; flex-direction:row;}
}
.searchBarStyle {
    width: 300px !important;
    height: 10px;
    position: relative;
    left: 300px;
    top: -10px;
    margin-bottom: 40px;
}

.groupSubNabBoxStyle {
    margin-top: 50px;
}
.groupSubNavTableContainerStyle {
    width: 820px;
    box-shadow: none;
    outline: none;
}

.groupSubTabTableStyle {
    width: 100%;
}

.groupSubTabTableHeadStyle {
    width: 100%;
}

.groupSubTabTableHeadRowStyle {
    width: 50%;
}

.usersSubNavTabLinksMapperBoxStyle {
    padding-top: 20px;
    padding-left: 10px;
    margin-bottom: 2;
}

.userSubNavTabAddUserButtonStyle {
    font-weight: bold;
    height: 27px;
    right: 230px;
    top: 15px;
}

.usersSubNavTabCopyUserEmailStyle {
    padding-left: 10px;
    font-size: medium;
    cursor: pointer;
}

.permissionTabLabelStyle {
    margin-right: 900px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
}

.groupSubTabGroupNameCellStyle {
    padding-bottom: 10px;
    font-size: 18px;
    width: 230px;
    text-align: center;
    font-weight: bold;
}

.groupSubTabGroupDescCellStyle {
    padding-bottom: 10px;
    font-size: 18px;
    width: 250px;
    text-align: center;
    font-weight: bold;
}

.groupSubTabBodyTableRow {
    padding-top: 10px;
    height: 70px;
    align-content: row;
    cursor: pointer;
}

.groupSubTabBodyGroupName {
    font-weight: 500;
    text-align: center;
    height: 100%;
}

.addUsersModalTableRow {
    width: 40px;
    cursor: pointer;
}

.addUsersModalTabEmailCell {
    width: 600px;
    font-size: 16px;
}

.addUsersModalGroupsCell {
    font-size: 14px;
}

.groupSubTabBodyGroupDescription {
    text-align: center;
    height: 100%;
}

.userTableDiv {
    display: inline-block;
    width: 900px;
    padding-right: 200px;
}

.usersPagiCell {
    display: flex;
    border-bottom: none;
}

.addUserTableStyle {
    margin-top: 5px;
    width: 630px;
    border-collapse:collapse;
    box-shadow: none;
    border-spacing: 0 5px;
    border: 1px solid white;
    outline: none;
}


.breadcrumbsStyle {
    padding-left: 20px;
    padding-top: 20px;
}
