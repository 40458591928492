.hidden {
    overflow: hidden;
}
.tabWrapper {
    background-color: white;
    padding: 16px;
    text-align: center;
    height: 320px;
    width: 100%;
}
.textAllignLeft {
    text-align: left;
}

.navButton {
    margin: 16px;
}

.navigationStepImages {
    width: 55%
}

.navigationFirstStepImage {
    width: 70%;
}

.textStyling {
    margin-bottom: 10px;
}

.homeImageContainer {
    margin: 0
}
