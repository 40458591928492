.refDivStyle {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-right: 30px;
}

.refDivStyleAtWizard {
        display: flex;
        justify-content: center;
        margin-top: 50px;
}

.outerBoxStyle {
    margin-left: 20px;
}

.inputFieldStyle {
    width: 100px;
    height: 100px;
}

.referralAllocationLabelActive {
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
    margin-top: 27px;
    color: black;
}

.referralAllocationLabelDisabled {
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
    margin-top: 27px;
    color: grey;
}
.innerBoxStyleDisabled {
   justify-content: center;
   border: solid;
   border-width: 2px;
   border-radius: 10px;
   margin-right: 20px;
   min-height: 320px;
   min-width: 400px;
   border-color: #d6d6d0;
   box-shadow: 1px 2px 2px rgb(202, 198, 198);
}
.toolTipDiv {
    display: flex;
}


.innerBoxStyleActive {
    justify-content: center;
    border: solid;
    border-width: 2px;
    border-radius: 10px;
    margin-right: 20px;
    min-height: 320px;
    min-width: 400px;
    border-color: black;
    box-shadow: 1px 2px 2px rgb(202, 198, 198);
 }

.currencyIdSelectStyle {
    text-align: left;
    margin-top: 10px;
    width: 390px;
    margin-left: 5px;
}

.emailSendingSelectorBox {
    margin-top: 40px;
    margin-left: 12px;
}

.checkboxTypographyStyle {
    padding-right: 2px;
}

.referralEnableBoxStyle {
    margin-bottom: 5px;
    margin-left: 10px;
}

.referralEnableBoxStyleAtWizard {
    margin-right: 247px;
}

.currencyAmountInputStyle {
    width: 390px;
    margin-left: 5px;
}

.limitTooltipGrid {
    justify-content: left;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
}

.limitTitleDisabled {
    opacity: 0.5;
}

.limitTitleActive {
    opacity: 1;
}

.limitInputStyle {
    width: 390px;
    margin-left: 5px;
    margin-bottom: 10px;
}
