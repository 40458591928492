.prize-box-main {
    width: 50%;
    margin: 0 auto;
}

.prize-box-content {
    width: 50%;
    text-align: left;
    display: inline-grid
}

.prize-tooltip-text {
    text-align: left;
    align-items: center;
}

.prize-tooltip-text span{
    height: auto;
}

.input-space {
    margin-bottom: 10px;
}
.prize-localization-input {
    height: 60px;
}

.nav-tabs {
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 15px;
    max-width: 86%;
}

.subnav {
    display: flex;
    justify-content: center;
}

.subnav-link {
    font-weight: bold;
    font-size: 12px;
    opacity: 1;
    color: #43425d
}

.prize-type-title {
    text-align: left;
    margin: 10px 0;
}

.radio-group-form {
    display: inline-grid;
    text-align: left;
    width: 60%;
    margin-bottom: 30px;
}

.prize-state-box {
    width: 70%;
    margin: 0 auto;
}

.button-group {
    margin-top: 5px;
    height: 53px;
    display: inline-table;
}

.button-group [type="button"][aria-pressed="true"] {
    color: white;
    background-color: black;
}

.additional-details-content{
    width: 40%;
    text-align: left;
    display: inline-grid
}

.addPrize-stepper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px
}
