.winningMomentsHeader h3 {
    margin: 20px 0px 20px 0px;
}

.winningMomentsHeader button,
.generateMoments {
    float: left;
    height: 22px;
    margin-right:10px;
    text-transform: capitalize;
    font-weight: bold;
}
.winningMomentsHeader button,
.generateForSelected {
    float: left;
    height: 22px;
    margin-right:10px;
    text-transform: none;
    font-weight: bold;
}

.winningMomentsHeader button:first-of-type {
    margin-right: 10px;
}
.circleIconActive {
    font-size: 10px;
    margin-top: 5px;
    color: #5FB296;
    text-align: left;
}

.circleIconInactive {
    font-size: 10px;
    margin-top: 5px;
    text-align: left;
    color: #E8E9EC;
    stroke: #EC1E24;
}

.selectedItemsN {
    text-align: right;
    font-style: italic;
    margin-right: 20px;
}

.changesButtonsBox {
    text-align: right;
}
.discardChangesButton {
    margin-right: 15px;
}
