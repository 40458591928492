.icon-wrapper {
    height: 20px;
    overflow: hidden;
}

.arrowUp {
    position: relative;
    bottom: 12px;
    display: block;
    font-size: 30px
}

.arrowDown {
    position: relative;
    bottom: 33px;
    display: block;
    font-size: 30px
}
