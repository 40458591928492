.modal_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    box-shadow: 24px;
    padding: 8px 32px;
}
.enhancedBody {
    border-radius: 50px;
    text-align: center;
    margin-bottom: 40px;
}

.subheader {
    text-align: left;
    margin-bottom: 5px;
}
.neumorphicAccordionBox {
    max-height: 300px;
    overflow: scroll;
    background: #e0e0e0;
    margin-bottom: 40px;
    box-shadow:  -15px 15px 40px #bebebe,
                15px -15px 40px #ffffff;
}
.button {
    float: right;
}

.tier-subheader {
    text-align: left;
    margin-bottom: 5px;
    padding-left: 10%;
}

.tier-input {
    text-align: center;
}

.tooltip-link {
    font-size: 12px;
    color: #f30000;
    text-decoration: none;
}

.voucher-upload-subheader {
    text-align: left;
    margin-bottom: 5px;
}
