.skeleton {
    overflow: hidden;
    margin-top: 20px;
}

.skeletonGrid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.skeletonBox {
    width: 210px;
    margin-right: 0.5%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.skeletonBoxChild {
    padding-top: 0.5%;
}
