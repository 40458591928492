.PrivateSwitchBase-input {
    border-color: white;
}

.checkbox-class {
    background-color: white;
    color: #43425D;
    width: 5px;
    height: 5px;
    margin: 5px;
}

.full-opacity {
    opacity: 1;
}

.half-opacity {
    opacity: 0.5;
}

.minAgeNoTooltip {
    margin-top: 12px
}
