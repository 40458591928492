.root {
    width: 60px;
    height: 40px;
}

.root .MuiSwitch-thumb {
      width: 18.35px;
      height: 19px;
}
.root .MuiSwitch-switchBase.Mui-checked {
      color: #219376
    }

.root .MuiSwitch-switchBase.Mui-checked:hover {
        background: none
      }

.root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: #219376
    }
