.tooltip_text {
    font-size: 15px;
    font-family: Roboto, sans-serif;
    color: #43425D;
    background: #FFFFFF
}
.tooltipGrid {
    justify-content: center;
    align-items: center
}

.justifyContentLeft {
    justify-content: left;
    align-items: center
}

.icon-style {
    color: #43425D;
}
