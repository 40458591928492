.tablePagination {
    display: flex;
}

.paginationMenu {
    background-color: #A4AFB7;
    font-size: 10px;
}

.vouchersContainer {
    box-shadow: none;
}

.vouchersTable {
    padding: 0 10px;
    border-spacing: 0 15px;
    border-collapse: separate;
}

.vouchersTable .voucherHeader th {
    border: none;
    font-size: 14px;
    color: #4e4f55;
}
.vouchersTable .voucherRow td {
    border-top: 1px solid #E8E9EC;
    border-bottom: 1px solid #E8E9EC;
}
.vouchersTable .voucherRow td:first-child {
    border-left: 1px solid #E8E9EC;
}
.vouchersTable .voucherRow td:last-child {
    border-right: 1px solid #E8E9EC;
}
.voucherRow td:not(:first-of-type) {
    font-size: 13px;
    color:#A4AFB7;
}
.copyPrizeId {
    font-size:20px;
    position:relative;
    top:6px;
}
.helpfulLinks {
    padding: 0 10px;
    margin-top: 10px;
    display: inline-grid;
    font-size: 12px;
}

.edit-page-wrapper {
    margin: 10px 0px 20px 0px;
}
.changesButtons {
    height: 40px;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
  }

.changesButtons a, .changesButtons button:first-child{
    margin-right: 10px;
  }

.changesButtons a, .changesButtons button {
    min-width: 148px;
  }

.tabHeader h3 {
    margin: 20px 0px 20px 0px;
}

.tabHeader button,
.firstHeaderButton {
    float: left;
    height: 22px;
    padding: 0px 30px;
    margin-right:10px;
    text-transform: capitalize;
    font-weight: bold;
}
