.details-checkbox-tooltip {
    text-align: left;
    align-items: center;
}

.image-promotion-checkbox-tooltip {
    text-align: left;
    align-items: center;
}

.image-promotion-checkbox-tooltip span {
    height: auto;
}

.details-checkbox-tooltip span{
    height: auto;
}

.promo-details {
    width: 450px;
    margin: 0 auto;
}

.promo-options {
    text-align: left;
    margin-left: 55px;
    margin-bottom: 20px;
}

.captcha-box{
    text-align: left;
    margin-top: 15px;
}

.captcha-checkbox {
    margin-top: 10px;
    padding-left: 5px
}
.voucher-checkbox {
    padding-left: 5px
}

.interruptedFlowBox {
    margin-top: 10px
}

.cngAddDetailsBox {
    margin-top: 10px
}

.useStatusReservedBox {
    padding-left: 5px
}

.imagePromoEntryCheckbox {
    margin-top: 10px;
    padding-left: 5px;
}
