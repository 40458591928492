.campaignCard {
  min-width: 275;
  max-width: 900;
  max-height: 200;
  box-shadow: 4px 6px 20px -4px rgba(0,0,0,0.13);
}
.campaignCardContent {
  margin-top:20px;
  text-align: left;
  margin-left:15px;
}
.campaignCardContent p {
  color:gray;
}

.promotionNameWrapper {
  overflow:hidden;
}
.promotionNameWrapper h6 {
  float:left;
  font-size:16px;
  font-weight: bold;
}
.manageButtonStyles {
  font-size: 10px;
  float:right;
  font-weight: bold;
}
.cardFooter {
  width:95%;
  display: inline-block;
  padding:5px 0px;
}
.campaignStatus {
  float: left;
  margin-top: 5px;
  margin-left:20px;
}
.livePromotionsCountLabelStyles {
  font-size: 13px;
  color: #F30000;
  text-align: right;
}
.draftPromotionsCountLabelStyles {
  font-size: 13px;
  color: #6AC9CE;
  text-align: right
}
