.adornment-input-end {
    position: absolute;
    border-radius: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12%;
    height: 100%;
    background-color: black;
}
.adornment-input-end:hover {
    background: black;
}
.adornment-input-start {
    position: absolute;
    border-radius: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12%;
    height: 100%;
    background-color: black;
}
.adornment-input-start:hover {
    background: black;
}

.adornment-input-start:disabled {
    background: grey;
}
.spinner-box {
    height: 40px;
    width: 320px;
    background-color: white;
}
.spinner-icon {
    color: white;
    stroke: black;
}
