.limits-form-container {
    margin-top: 10px;
    margin-bottom: 10px
}
.limits-participation-box {
    text-align: left;
}

.limits-form-items {
    margin-bottom: 10px
}

.limits-form-items.alwaysWin {
    margin-top: 40px;
}

.form-control-participation-limits,
.form-control-alwaysWin-limits {
    gap: 15px
}

.number-input, input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-inner-spin-button {
        opacity: 1;
}

.vouchersTable .limitsRow td {
    border-top: 1px solid #E8E9EC;
    border-bottom: 1px solid #E8E9EC;
}

.vouchersTable .limitsRow td:first-child {
    border-left: 1px solid #E8E9EC;
}

.vouchersTable .limitsRow td:last-child {
    border-left: 1px solid #E8E9EC;
    border-right: none;
    border-bottom: none;
    border-top: none;
}

  .tier-limits-header {
      margin-top: 10px;
  }

  .configureTiers {
    cursor: pointer;
  }

  .limits-table-cell {
    width: 150px;
  }
