.gridMainContainer {
    align-items: flex-end;
    direction: row;
    margin-bottom: 16px;
}

.gridSubContainer {
    direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.gridPincodeOrigin {
    padding-left: 12px;
    align-items: flex-end;
    direction: row;
    display: block;
}

.gridMixcodes,
.gridPrizeRatios {
    align-items: flex-end;
    direction: row;
    justify-content: end;
}
