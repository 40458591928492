.prizesWrapper {
    margin-right: 30px;
}

.prizesHeader button:first-of-type {
    margin-right: 10px;
}

.assignedPrizesTable {
    box-shadow: none;
    min-height:525px;
}

.assignedPrizesTable table {
    margin: 0px 10px;
    width: 98%;
    border-spacing: 0px 10px;
    border-collapse: separate;
}

.assignedPrizesTable .tableHeader th,
.assignedPrizesTable td {
    border: 0px;
    vertical-align: middle;
}

.assignedPrizesTable .addNew td {
    border-top: 1px dashed rgba(0, 0, 0, 0.689);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.689);
}

.assignedPrizesTable .addNew td:first-child {
    border-left: 1px dashed rgba(0, 0, 0, 0.689);
    border-radius: 5px 0px 0px 5px;
}

.assignedPrizesTable .addNew td:last-child {
    border-right: 1px dashed rgba(0, 0, 0, 0.689);
    border-radius: 0px 5px 5px 0px;
}

.addIcon {
    position: relative;
    top: 5px;
    margin-right: 10px;
}

.copyPrizeId {
    font-size:20px;
    position:relative;
    top:6px;
}

.assignedPrizesTable .prizeRow td {
    vertical-align: baseline;
    border-top: 1px solid rgba(208, 206, 206, 0.689);
    border-bottom: 1px solid rgba(208, 206, 206, 0.689);
    margin-bottom:5px;
}

.assignedPrizesTable .prizeRow td:first-child {
    border-left: 1px solid rgba(208, 206, 206, 0.689);
}

.assignedPrizesTable .prizeRow td:last-child {
    border-right: 1px solid rgba(208, 206, 206, 0.689);
}

.numIndex {
    background-color: grey;
    padding:9px 13px;
    border-radius: 20px;
    color:white;
}

.prizeName {
   font-weight: bold;
}

.greyText {
    color: rgb(161, 159, 159);
}

.sortIcons .MuiSvgIcon-root  {
    display:block;
}

.sortIcons .MuiSvgIcon-root:first-child  {
    position:relative;
    bottom:9px;
    right:3px;
}

.sortIcons .MuiSvgIcon-root:last-child  {
    position:relative;
    bottom:33px;
    right:3px;
}

.sortIcons {
    color: grey;
    height:20px;
}

.sortIcons svg{
   font-size: 32px;
}
.currentSortingIcon {
    color:black;
}
