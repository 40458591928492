.allocation-rules-box-main {
    width: 40%;
    margin: 0 auto;
    margin-top: 20px
}

.rules-radio-group {
    margin-top: 50px;
    display: inline-grid;
    text-align: left;
    width: 100%;
}

.step-box-content {
    margin-top: 50px;
    margin-bottom: 60px;
    width: 50%;
    text-align: left;
    display: inline-grid
}

.validity-tooltip-text {
    text-align: left;
    align-items: center;
}

.titles-style {
    margin-top: 20px
}

.stepper-size {
    width: 50%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
}

.allocate-rules-save-box {
    width: 80%;
    margin: auto
}

.delete-buttons-style {
    margin-top: 5px;
}
