.pe-main-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pe-heading {
    text-align: left;
    margin-top: 10px;
}

.pe-selector {
    width: 30%;
    margin-top: 50px;
}

.pe-button-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.pe-button {
    min-width: 180px;
    font-style: 'bold';
}

.pe-message {
    margin-bottom: 10px;
}

.custom-participation-box {
    margin-top: 20px;
}

.winners-table {
    margin-top: 40px;
    max-width: 98%;
}
