.formControl {
    width:82%;
    margin-top:8px;
}
.subTitle{
    padding-bottom:20px,
}
.autoCompleteRoot {
    background:white;
}
.tag {
    border-radius: 0px;
    border-bottom: 1px solid#000;
}
.tag .MuiSvgIcon-root {
    border-radius: 0px;
    border-left: 1px solid#000;
}
.dropDownIcon {
    font-size:50px;
    color: #000;
}
.endAdornment {
    right:-1px;
    top:2px;
}
.additionalOptionLink {
    padding-top:10px;
    text-align:left;
    cursor: pointer;
}

.boxCustomSize {
    width: 36.5%;
    padding-top: 25px;
}
