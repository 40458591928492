.file-form-group {
    width: 100%
}

.file-input-field{
    background-color: white;
    min-width: 65%;
}

.file-button{
    height: 56px;
    font-size: 14px;
    text-transform: capitalize;
}

.grid-container {
    margin-top: 8px;
    direction: row;
}

.grid-box {
    padding: 2px;
    margin-bottom: 5px;
    border: dashed;
    border-color: #a4afb7;
    border-width: 1px;
    border-radius: 10px
}

.file-name {
    color: #a4afb7;
    margin-bottom: 2px;
}

.existing-file-name {
    color: #a4afb7;
    margin-top: 5px;
}

.delete-file-grid {
    text-align: center;
    position: relative;
    top: 5px
}

.delete-icon {
    color: #a4afb7
}
