.textField {
    margin-bottom: 20px;
}

.componentWrap {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: left;
    background-color: white;
}

.searchBarStylesPromotions {
    width: 560px;
    height: 10px;
    position: relative;
    left: 140px;
    top: 15px;
    background-color: white;
}

.assign-promo-list-table .tableClass {
    min-width: 700;
    border-collapse: separate;
    border-spacing: 0 15px;
    border: 1px solid white;
    outline: none;
}

.cellX {
    font-weight: bold;
    font-size: 32px;
}


.buttonManageCreate {
    float: right;
    font-weight: bold;
    background-color: black;
    color: white;
    min-width: 120px;
    height: 25px;
    border-radius: 4px;
    opacity: 1;
    text-transform: none
}

.buttonManageCreate:hover {
    background-color: black;
}

.buttonManageCreate.disabled {
    float: right;
    font-weight: bold;
    background-color: #d3d3d3;
    color: gray;
    min-width: 120px;
    height: 25px;
    border-radius: 4px;
    opacity: 1;
    text-transform: none
}

.assignPromotionsWrapper {
    text-align: right;
    margin-right: 200px;
    padding-top: 40px;
}

.borderStyleFirstElement {
    border-style: dashed;
}

.borderStyleSecondElements {
    border-style: solid;
}

.tableFirstCell {
    color: black;
    border-top: 1px dashed #5F5F5F;
    border-bottom: 1px dashed #5F5F5F;
    border-left: 1px dashed #5F5F5F;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: "pointer",
}

.tableMiddleCell {
    border-top: 1px dashed #A4AFB7;
    border-bottom: 1px dashed #A4AFB7;
}

.tableLastCell {
    border-top: 1px dashed #5F5F5F;
    border-bottom: 1px dashed #5F5F5F;
    border-right: 1px dashed #5F5F5F;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tableFirstCellDisplayData {
    color: black;
    border-top: 1px #A4AFB7 solid;
    border-bottom: 1px #A4AFB7 solid;
    border-left: 1px #A4AFB7 solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    opacity: 0.8;
}

.tableMiddleCellDisplayData {
    color: #A4AFB7;
    border-top: 1px #A4AFB7 solid;
    border-bottom: 1px #A4AFB7 solid;
    padding: 0;
}

.tableFirstCellDisplayDataRed {
    color: black;
    border-top: 1px #A4AFB7 solid;
    border-bottom: 1px #A4AFB7 solid;
    border-left: 1px #A4AFB7;
    color: red;
}

.tableFirstCellDisplayDataGreen {
    color: black;
    border-top: 1px #A4AFB7 solid;
    border-bottom: 1px #A4AFB7 solid;
    border-left: 1px #A4AFB7;
    color: green;
}

.tableLastCellDisplayData {
    border-top: 1px solid #A4AFB7;
    border-bottom: 1px solid #A4AFB7;
    border-right: 1px solid #A4AFB7;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rowCells {
    background-color: white;
    opacity: 0.7;
    color: black;
    padding: 0;
}

.row-item-assign-promo {
    display: flex;
    gap: 5px;
    align-items: center;
}

.searchWrapper {
    background-color: white;
}

.color {
    background-color: red;
    padding: 15px;
}

.backgroundWhite {
    background-color: white;
}


.assign-promo-list-table {
    box-shadow: none;
}

.table-class thead  th{
    border:none;
}

.MuiTableRow-root {
    padding : 0 16px;
}

.MuiTableCell-root{
  padding : 5px 16px;
}

.promotion-name {
    display: flex;
    gap: 5px;
    align-items: center;
}

.circle {
    height: 25px;
    width: 25px;
    background-color: black;
    color:white;
    border-radius: 50%;
    display: inline-block;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-icon-button-assign-promo {
    display: flex;
    align-items: center;
}

.time-box-assigned-promotion {
    width: 600px;
}

.participation-box-rolling-period {
    margin-top: 10px;
    margin-bottom: 10px;
}

.add-new-promo-button {
    background: black;
    color: white;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 10px;
}
.paginationCell {
    display: inline-flex;
    border-bottom: none;
}

.add-new-promo-button:hover {
    background-color: black;
}

.add-new-promo-button.disabled {
    background: #d3d3d3;
    color: gray;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 10px;
}

.search-bar-form-control {
    float: right;
}
