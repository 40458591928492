.campaignsBigLabelStyles {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  width: 122px;
  height: 50px;
  left: 1px;
  top: 65px;
}
.createNewCampaignButtonStyles {
  position: relative;
  left: 100px;
  font-weight: bold;
  bottom: 10px;
}
.searchBarStyles {
  width: 460px;
  height: 10px;
  position: relative;
  left: 125px;
  top: -10px;
  margin-bottom: 50px;
}
.breadCrumbsStyles {
    position: relative;
    top: 65px;
}
