.mixcodes-tap-wrapper {
    margin: 10px 0px 20px 0px;
}

.mix-codes-details {
    width: 80%;
}

.links-title{
    margin-top: 15px;
}

.mixcodes-main-box {
    display: inline-block;
}

.mixcodesChangesButtons {
    height: 40px;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 0;
    text-align: right;
  }

.mixcodesChangesButtons a, .mixcodesChangesButtons button:first-child{
    margin-right: 10px;
  }

.mixcodesChangesButtons a, .mixcodesChangesButtons button {
    min-width: 148px;
  }
