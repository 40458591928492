.search-bar-assign-promotions-component > div {
    background-color: white;
    border: 1px solid black;
    border-radius: 7px;
    width: 500px;
}

.assign-promotions-wrapper {
    background-color: white;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    width: 100%;
    height: 100%;
}
