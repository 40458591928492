.currencies-main-box {
    width: 80%;
    display: inline-block;
}

.currencies-tap-wrapper {
    margin: 10px 0px 20px 0px;
}

.currencies-details {
    width: 60%;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 20px;
}
