.vouchersTitle {
    padding: 5px 10px;
    margin: 20px 0px 10px 0px;
}

.prizeName {
    font-weight: bold;
    margin-bottom: 0px !important;
    font-size: 15px;
    color: #4D4F5C;
}

.prizeIdTitle {
    font-size: 13px;
    color: #4D4F5C;
    opacity: 1;
}

.prizeIdText {
    font-size: 13px;
    color: #4D4F5C;
    opacity: 0.33;
}

.uploadVouchersIcon {
    font-size: 25px;
    color: #A4AFB7;
    opacity: 1;
}

