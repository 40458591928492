.createCampaignBox {
    margin-top:50px;
}
.stepperButton {
    min-width:160px;
    padding: 9px 30px;
}
.campaignStepImages {
    margin-bottom: 24px;
    padding-right: 80px;
}
.additionalOptionLink {
    text-align: left;
}
.buttonWrapper {
    display: block;
    margin-top: 25px;
}
.buttonWrapper button:first-child {
    margin-right:23px;
}
.wrapper {
    margin: 0 auto;
    display: inline-table;
}
.wrapper p {
    padding-top:15px;
    color: #43425D;
}
.datesWrapper {
    max-width: 350px;
    margin:0 auto;
}
.datesWrapper p {
    padding-top: 0;
}
.button {
    display: inline-block;
}
.stepper {
    width: 424px;
    margin: 0 auto;
    margin-top: 46px;
}

.textBox {
    width: 620px;
    margin: 0 auto
}

.campaign-name-input {
    width: 350px;
    margin: 0 auto
}

.campaign-full-name-tooltip {
    display: inline-flex;
    align-items: center;
}

.underline-experience-tool {
    text-decoration: underline;
}
