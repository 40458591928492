.create-campaign-success {
    font-size: 27px;
    font-weight: 600;
    color: #43425D;
}

.font-weight-bold {
    font-weight: bold;
}

.create-campaign-success-icon {
    font-size: 80px;
    color: #2FBC6B;
}

.save-campaign-btn {
    text-transform: none;
    display: block;
    min-width: 350px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 600;
}

.view-draft-btn {
    margin-top: 15px;
}
.save-campaign-tabs-wrapper{
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}
.save-campaign-tabs-wrapper button{
    min-height: 48px;
    padding-bottom: 0px;
    font-size: 17px;
    padding-top: 0px;
}

.copy-input-box {
    margin-top: 10px;
}
