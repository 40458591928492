.copy-input {
    width: 65%;
    background-color: white;
}

.copy-input-grid {
    width: 350px;
    margin: 0 auto;
}

.copy-input-button {
    text-transform: none;
    width: 35%;
}
