.headerWrapper {
    background-color: white;
    font-family: Roboto, sans-serif;
    padding: 0;
    position: static;
}
.headerButton {
    position: relative;
    left: 10px;
    top: 13px;
    height: 30px;
    color: inherit;
}
.toggledOn {
    font-weight: bold;
    border-bottom: 4px solid black
}
.profileSection {
    display: flex;
    flex-grow: 1;
}
.profileIcon {
    max-width: 130px;
}
.profileButton {
    float: left;
    color: inherit;
}

.cardMedia {
    box-shadow: none;
}
