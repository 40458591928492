.pageContent {
    background:white;
    margin-top:30px;
    margin-bottom:20px;
    padding-bottom:30px;
    min-height:300px;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.31);
}
.pageContent .rightContent {
    padding-top:10px;
}
.pageContent .rightContent .MuiTypography-h3 {
    margin-bottom:10px;
}
.pageContent .leftContent {
    border-right: 1px solid #E0E0E0;
}
.pageContent .MuiTextField-root {
    width:100%;
}
.pageContent .file-input-field {
    width:65%;
}
.pageContent .MuiGrid-root .tooltip {
    margin-bottom:0px;
}
.disabled p{
    color:rgba(0, 0, 0, 0.38);
}
.generalInfoForm .submitBtn {
    float:right;
    text-transform: none;
    font-weight: 800;
    padding:8px 16px;
    margin-right:0;
}
.generalInfoForm h3 {
    margin-top:10px;
}
.generalInfoForm .MuiGrid-root {
    margin-bottom: 10px;
}
.generalInfoForm .MuiTypography-body1 {
    margin-bottom:8px;
}
.leftNav button{
    border-bottom:0px;
    justify-content: left;
    padding-top:20px;
    padding-bottom:20px;
    padding-left:18px;
    border-radius: 0px;
    text-transform: none;
    font-size:0.995rem;
    font-weight: 300;
}

.leftNav button:hover {
    background:transparent;
}
.leftNav .currentOptionSelected {
    border-right: 10px solid #000;
    font-weight: 600;
}

.leftNav .endIcon {
    position:absolute;
    right: 1rem;
    top: 1.2rem;
}

.leftNav .endIcon-notSelected {
    position:absolute;
    right: 1rem;
    top: 1.2rem;
    margin-right: 10px;
}

.pageContent h3 {
    font-weight:200;
}
.generalInfoForm p.mechanicHeader {
    text-transform: uppercase;
    font-weight: 500;
}
.BackBtn {
    text-transform: none;
    font-weight: 900;
    padding:2px 15px;
    margin-top:15px;
}
.BackBtn .MuiButton-startIcon {
    margin-right:0px;
}
.pageContent .helpfulLinks {
    margin-top:20px;
}
.pageContent .helpfulLinks a {
    display:block;
    margin-bottom:10px;
}

.subnav-edit-promo {
    font-weight: bold;
    font-size: 12px;
    opacity: 1;
    color:#43425D
}
