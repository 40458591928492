/* leaving the file until all files changed with Typography */
* {
  font-family: Roboto, sans-serif;
}

h1 {
  font-size: 3em;
  font-weight: 500;
}

h2 {
  font-size: 2.5em;
  font-weight: 500;
}

h3 {
  font-size: 2em;
  font-weight: 600;
  color: #43425D
}

h4 {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
  color: #43425D
}

p {
  font-size: 16px;
  font-weight: normal;
  color: #43425D
}

body {
  background-color:#f2f2f2;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
.bodyWrapper {
  padding:0 20px;
  text-align: center;
}

.input-text {
  background-color: white;
}

.buttons {
  height: 40px;
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
}

.buttons a, .buttons button:first-child{
  margin-right: 10px;
}

.buttons a, .buttons button {
  min-width: 148px;
}

.language-selector {
    min-width: 100%;
}
