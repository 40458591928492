.groupsSubTabPageStyle {
    width: 1300px;
}

.pageContentAdminPanel {
        background:white;
        margin-top:15px;
        margin-bottom:100px;
        margin-left: 20px;
        padding-bottom:30px;
        min-height:300px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.31);
    }

.usersSubTabPageStyle {
    width: 1700px;
}

.wrapperDiv {
    display: flex;
    justify-content: center;
}
