.error-icon {
    color: #F30000;
    font-size: 80px;
    margin-top: 80px;
    margin-bottom: 40px;
}

.home-button {
    width: 100px;
    margin-top: 24px;
    margin-bottom: 80px;
}

