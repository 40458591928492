  .textFieldWrap { 
    margin-top:24px; 
  }
  .saveButton {
    float:right;
  }
  .componentWrap {
    width:100%;
    margin: auto;
    background-color: white; 
    padding: 16px; 
    text-align: left;  
    overflow:auto; 
  }
  .marketsBox {
    margin-top: 24px;
  }
  .brandsSelectWrapper { 
    width:80%;
  }